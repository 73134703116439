import { useEffect, useState } from "react";
// for work_images
import schoolfull from "../assets/images/work_images/school-full.png";
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaRegUser, FaAward, FaDev} from "react-icons/fa"
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "PHP",
      title: "School Us CMS",
      img: schoolfull,
      imgSmall: workSmall1,
      bg: "#EEE",
      client: "Andishe School",
      langages: "PHP , JS , HTML ,CSS , Android",
      link: "https://www.sch.idmrt.com",
      linkText: "Click To Show ",
      description: "",
    },
    {
      id: "2",
      tag: "Next JS",
      title: "Clinic Milad Mobile",
      img: work2,
      imgSmall: workSmall2,
      bg: "#EEE",
      client: "M.Babaei",
      langages: "React , Next JS , Tailwind",
      link: "https://www.miladmobile.com",
      linkText: "Click To Show ( NOT RELEASED YET )",
      description: "",
    },
    {
      id: "3",
      tag: "Multi Platform",
      title: "Dastyar App",
      img: work3,
      imgSmall: workSmall3,
      bg: "#EEE",
      client: "Dastyar CO",
      langages: "PHP , JS , CSS , Android , Objective C , Swift ",
      link: "/assets/files/dastyar.pdf",
      linkText: "Site Closed ! Download PDF Interview",
      description: "",
    },
    {
      id: "4",
      tag: "Other",
      title: "H&H Perfume Shop",
      img: work4,
      imgSmall: workSmall4,
      bg: "#EEE",
      client: "H&H Co",
      langages: "Wordpress",
      link: "https://shop.hh-perfume.com",
      linkText: "Preview H & H Perfume Shop",
      description: "",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "9 مرداد",
      category: "Inspiration",
      title: "چگونه با ایجاد یک لیست ایمیل صاحب مخاطبان خود شوید",
      bg: "#FCF4FF",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blogSmall4,
      date: "1 مرداد",
      category: "Inspiration",
      title: "هر آنچه که باید در مورد دسترسی به وب بدانید",
      bg: "#EEFBFF",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
    {
      id: "2",
      img: blog2,
      imgSmall: blogSmall2,
      date: "26 تیر",
      category: "Web Design",
      title: "پنجره میدونه کنارت بگه",
      bg: "#FFF0F0",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
    {
      id: "5",
      img: blog5,
      imgSmall: blogSmall5,
      date: "10 تیر",
      category: "Inspiration",
      title: "10 ابزار برتر برای یادگیری عمیق در سال 1401",
      bg: "#FCF4FF",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
    {
      id: "3",
      img: blog3,
      imgSmall: blogSmall3,
      date: "18 خرداد",
      category: "Inspiration",
      title: "چگونه با ایجاد یک لیست ایمیل صاحب مخاطبان خود شوید",
      bg: "#FCF4FF",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
    {
      id: "6",
      img: blog6,
      imgSmall: blogSmall6,
      date: "12 خرداد",
      category: "Inspiration",
      title: "هر آنچه که باید در مورد دسترسی به وب بدانید",
      bg: "#EEFBFF",
      description: "پیر مردی هر روز تو محله می دید پسر کی با کفش های پاره و پای برهنه با توپ پلاستیکی فوتبال بازی می کند، روزی رفت ی کتانی نو خرید و اومد و به پسرک گفت بیا این کفشا رو بپوش…پسرک کفشا رو پوشید و خوشحال رو به پیر مرد کرد و گفت: شما خدایید؟! پیر مرد لبش را گزید و گفت نه! پسرک گفت پس دوست خدایی، چون من دیشب فقط به خدا گفتم كه کفش ندارم… هر نفسی که فرو می‌ بریم، مرگی را که مدام به ما دست‌ اندازی می‌کند پس می‌زند... در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.",
    },
  ];

 

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About Me",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "My Works",
      link: "/samples",
      icon: <FaDev />,
    },
    {
      id: "05",
      name: "Contact Me",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon5,
      title: "HomeService App (Dastyar.com)",
      des: "This application is a comprehensive application for requesting cleaning services, elevator services, on-site photography, tourism services, real estate consultants, etc. The programming language used in this application is PHP, JavaScript, Java, Swift, and in the form of an Android application, a management control panel through the website, a web application for access from all platforms, and an iOS application. He worked for 2 years. ",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon5,
      title: "Andishe School Application",
      des: "Super Application For School ! Demo NEW Version In : sch.idmrt.com _ Designed For Desktop ( WebApp ) & Android , This App Used For andisheapp.ir ( Andishe School ) For 6 Years .. i Used PHP , HTML , JS , Java for create this app . Feature THis App For Example IS : Obtaining GPA, mid-term and end-of-semester exams, students' disciplinary status, students' and parents' communication with the school's principal and principal through the internal messenger, etc.",
     color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon5,
      title: "H&H Perfume Website (hh-perfume.com)",
      des: "This Website run with Pyton for frontend and no backend in main website , BUT Create Shop.hh-perfume.com With Wordpress and design theme for shopping website . this site now availble to use",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon5,
      title: "Programming Many Android Applications",
      des: "During the years that I learned to program, I designed more than a dozen Android applications that were placed in the domestic markets of Iran and were mostly web-based and practical tools.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon5,
      title: "Website Created With Wordpress",
      des: "I have designed many sites for myself, students and clients using WordPress",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon5,
      title: "Teaching Android Programming",
      des: "Teaching Android programming in the university to students and those interested in programming",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "Last Education",
      title: "Bachelor of computer software",
      place: "Rouzbahan Univercity",
      bg: "#FFF4F4",

      
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "2017-2019",
      title: "Full Stack Developer OF Dastyar Company",
      place: "Science and Technology Park",
      bg: "#EEF5FA",

      id1: "05",
      date1: "2019-Now",
      title1: "Freelancer Developer",
      place1: "Home",
      bg1: "#F2F4FF",

      id2: "06",
      date2: "2019-Now",
      title2: "Ayandeh Bank OF IR",
      place2: "Sari , Mazandaran , IR",
      bg2: "#EEF5FA",
    },
    {
      type: "جوایز",
      icon: FaAward,
      id: "07",
      date: "1395 - 1397",
      title: "طراح گرافیک",
      place: "مجموعه دیجی‌کالا",
      bg: "#FCF4FF",

      id1: "08",
      date1: "1394 - 1395",
      title1: "برنامه‌نویس سمت وب",
      place1: "مجموعه اسنپ",
      bg1: "#FCF9F2",

      id2: "09",
      date2: "1393 - 1394",
      title2: "بهترین فریلنسر",
      place2: "مجموعه پونیشا",
      bg2: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "PHP",
      number: "100",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Frontend Developer",
      number: "100",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Android (JAVA)",
      number: "100",
    },
    {
      id: "04",
      color: "#5185D4",
      name: "Node JS",
      number: "70",
    },
    {
      id: "05",
      color: "#CA56F2",
      name: "ReactJS",
      number: "100",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "NEXT JS",
      number: "100",
    },
    {
      id: "07",
      color: "#DD22AD",
      name: "Wordpress ( Developer )",
      number: "100",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "تلفن",
      item1: "989125226561+",
      item2: "989125226561+",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "ایمیل",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "آدرس",
      item1: "آجودانیه، کوچه هفتم",
      item2: "ایران، تهران",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData, 
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
