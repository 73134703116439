import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import UseData from "../Hooks/UseData";

const Header = () => {
  const [menuOpen] = useState(false);

  const { menuItemTwo, NavLink } = UseData();

  return (
    <header className="lg:w-[526px] h-[120px] block top-20 p-[21px] lg:mx-0  my-4 mx-4 lg:mt-0 rounded-[12px] bg-white dark:bg-[#111111] ">
      {/* menu for mobile devices*/}
      <nav className={`${menuOpen ? "block mx-auto" : "block top-1"}`}>
        <ul
          className={`${
            menuOpen ? " block absolute left-0 top-20 w-full" : " flex  "
          }`}
        >
          {menuItemTwo.map((item) => (
            <NavLink
              key={item.id}
              className={` hover:bg-cyan-500 hover:text-white w-20 h-20 rounded-[10px] cursor-pointer transition-all duration-300 ease-in-out  font-poppins bg-[#F3F6F6] font-medium mx-2.5 text-center text-tiny text-gray-lite dark:text-[#A6A6A6] justify-center flex flex-col items-center`}
              to={item?.link}>
              <span className=" text-xl mb-2 text-center m-auto mt-2 px-2">{item?.icon}</span> {item?.name}
            </NavLink>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
