import React from "react";
import WorkExamples from "./CommonPortfilo";

const WorkSamples = () => {
  return (
    <div>
      <WorkExamples items={8} condition={false} />
    </div>
  );
};

export default WorkSamples;
